import PropTypes from "prop-types";
import * as React from "react";

// @mui/material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// vitals page components
import FormField from "../FormField";

import { useEffect, useState } from "react";

import { BarLoader } from "react-spinners";

// Get the current time in the format HH:MM
const getCurrentTime = () => {
  const date = new Date();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
};

function ShiftInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");

  const { formField, values, errors, touched } = formData;

  const { shiftName, starttime, endtime, planneddowntime } = formField;
  var { shiftName: shiftNameV, starttime: starttimeV, endtime: endtimeV, planneddowntime: planneddowntimeV } = values;

  // Set default value for starttime and endtime if they are empty
  useEffect(() => {
    if (!starttimeV) {
      values.starttime = getCurrentTime();
      starttimeV = values.starttime; 
    }
    if (!endtimeV) {
      values.endtime = getCurrentTime();
      endtimeV = values.endtime; 
    }
  }, [values, starttimeV, endtimeV]);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Shift Master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={shiftName.type}
                label={<span>{shiftName.label}<sup style={{ color: "red", fontSize: "small", fontWeight: "bolder", position: "relative", top: "2px" }}> *</sup></span>}
                name={shiftName.name}
                value={shiftNameV}
                placeholder={shiftName.placeholder}
                error={errors.shiftName && touched.shiftName}
                success={shiftNameV.length > 0 && !errors.shiftName}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormField
                type={starttime.type}
                label={starttime.label}
                name={starttime.name}
                value={starttimeV}
                placeholder={starttime.placeholder}
                success={starttimeV.length > 0 && !errors.starttime}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={endtime.type}
                label={endtime.label}
                name={endtime.name}
                value={endtimeV}
                placeholder={endtime.placeholder}
                success={endtimeV.length > 0 && !errors.endtime}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={planneddowntime.type}
                label={planneddowntime.label}
                name={planneddowntime.name}
                value={planneddowntimeV}
                placeholder={planneddowntime.placeholder}
                success={planneddowntimeV.length > 0 && !errors.planneddowntime}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}></Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for ShiftInfo
ShiftInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ShiftInfo;
