import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./LineProductionTable.css";
import VerticalBarChartMod from "examples/Charts/BarCharts/VerticalBarChartMod";
import DefaultDoughnutChartMod from "examples/Charts/DoughnutCharts/DefaultDoughnutChartMod";

const LineProductionTable = ({ data, table }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 880);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 880);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // console.log("daara",data.barchart.values)
  return (
    <Grid
      container
      className="lineproductiontablecontainer"
      pb={data?.barchart ? undefined : 13.2}
    >
      <Grid item xs={12} className="lineproductiontablegrid">
        {/* {data?.line} - {data?.shiftno} */}
        {data?.line}
      </Grid>
      <Grid item xs={12} mt={2} className="lineproductiontablegrid0">
        {data?.tablecell?.map((item, index) => {
          const key = Object.keys(item)[0];
          const value = item[key];
          return (
            <Grid container key={index}>
              <Grid item xs={6} pl={1} className="lineproductiontablegrid1">
                {key}
              </Grid>
              <Grid
                item
                xs={6}
                pr={1}
                className={
                  table == "oee"
                    ? "lineproductiontablegrid1"
                    : "lineproductiontablegrid2"
                }
              >
                {value}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Grid
        container
        mt={2}
        mb={3}
        className="lineproductiontablecharts"
        spacing={2}
      >
        {data?.barchart && (
          <Grid item xs={12} md={!isSmallScreen ? 6 : 12}>
            <VerticalBarChartMod
              title={`${data?.line} Production chart`}
              chart={{
                labels: data?.barchart?.labels,
                datasets: [
                  {
                    color: "info",
                    data: data?.barchart?.values,
                    backgroundColors: ["#1b73e9", "#42a046", "#dd3431"],
                  },
                ],
              }}
              options={{
                scales: {
                  y: {
                    title: {
                      display: true,
                      text: "Production (Nos)",
                    },
                  },
                },
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} md={data?.barchart && !isSmallScreen ? 6 : 12}>
          <DefaultDoughnutChartMod
            title={`${data?.line} Efficiency`}
            chart={{
              labels: data?.piechart?.labels,
              datasets: {
                label: "Projects",
                backgroundColors: ["success", "error"],
                data: data?.piechart?.values,
              },
            }}
            height={data?.barchart ? undefined : "200px"}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LineProductionTable;
