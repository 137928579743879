/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "shift-form",
  formField: {
    shiftName: {
      name: "shiftName",
      label: "Shift Name",
      type: "text",
      errorMsg: "Shift Name is required.",
    },
  
    
    starttime: {
      name: "starttime",
      label: "Start Time",
      type: "time",
      errorMsg: "Start Time Is Required.",
    },
    endtime: {
      name: "endtime",
      label: "End Time",
      type: "time",
      errorMsg: "End Time Is Required.",
    },
    planneddowntime: {
      name: "planneddowntime",
      label: "Planned Downtime (in minutes)",
      type: "number",
      errorMsg: "Planned Downtime Is Required.",
    },
  },
};

export default form;
