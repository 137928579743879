import { Card, CircularProgress, Grid, Input } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState, useCallback } from "react";
import "./OEE.css";
import LineProductionTable from "components/LineProductionTable/LineProductionTable";
import { useSelector, useDispatch } from "react-redux";
import { getBOMCreation } from "app/bomMaster/service/BOMCreationMaster";
import axios from "../../axiosinstance";
import MDDatePickerMod from "components/MDDatePickerMod";
import { reportServiceAction } from "redux/Actions/reportServiceAction";
import moment from "moment-timezone";
import { resetReportServiceList } from "redux/slices/getReportServiceData";

const defaultData = {
  linecode: "NA",
  line: "NA",
  shiftno: "NA",
  tablecell: [
    { "Available Time (Min.)": "-" },
    { "Availability Rate": "-" },
    { "Bottleneck cycle time (Sec)": "-" },
    { "Performance Rate": "-" },
    { "Rejection (Nos.)": "-" },
    { "Quality Rate": "-" },
    { OEE: "-" },
    { "OEE (Loss)": "-" },
  ],
  piechart: {
    labels: ["OEE", "OEE Loss"],
    values: [0, 0],
  },
};

const OEE = () => {
  const getCurrentDateString = () => {
    const date = new Date();
    return date.toISOString().slice(0, 10);
  };
  const getCurrentDateStringReadable = () => {
    const date = new Date();
    return `${String(date.getDate()).padStart(2, "0")} / ${String(
      date.getMonth() + 1
    ).padStart(2, "0")} / ${date.getFullYear()}`;
  };

  const getCurrentTimeString = () => {
    return `${String(currentTime.getHours()).padStart(2, "0")}:${String(
      currentTime.getMinutes()
    ).padStart(2, "0")}`;
  };

  const auth = localStorage.getItem("mmu-auth")
    ? JSON.parse(localStorage.getItem("mmu-auth"))
    : null;

  let lineproduction = auth
    ? JSON.parse(localStorage.getItem("lineproduction")) || {}
    : {};
  const initialDate = auth
    ? lineproduction.date || getCurrentDateString()
    : getCurrentDateString();
  const initialModel = auth ? lineproduction.model || "" : "";
  const [date, setDate] = useState(initialDate);
  const [model, setModel] = useState(initialModel);
  const [data, setData] = useState([defaultData]);
  const [oeeData, setOEEData] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());
  const { bomcreationmaster } = useSelector((state) => state.bomcreationmaster || []);
  const dispatch = useDispatch();
  const { getReportServiceData } = useSelector((state) => state || []);
  const productionMessage = useSelector(
    (state) => state.productionMessage.productionMessage || []
  );

    const currentTimeString = getCurrentTimeString();
  const [currentDateString, setCurrentDateString] = useState(
    getCurrentDateStringReadable()
  );

  useEffect(() => {
    dispatch(getBOMCreation());
  }, []);

  const formatNumber = (number) =>
    typeof number === "string" && number.includes(".")
      ? `${number.split(".")[0]}.${number.split(".")[1].slice(0, 2)}`
      : number;

  useEffect(() => {
    if (productionMessage) {
      const formattedDate = moment(productionMessage.date).format("YYYY-MM-DD");
      if (formattedDate === date && productionMessage.modelno === model) {
        const matchedItem = getReportServiceData?.list?.find(
          (i) => i.linecode === productionMessage?.linecode
        );
        if (matchedItem) {
          setData((prevData) => {
            return prevData.map((item) =>
              item.linecode === matchedItem.linecode
                ? {
                    linecode: productionMessage?.linecode,
                    line: productionMessage?.linename,
                    shiftno: productionMessage?.shift?.title.replace("-", " "),
                    tablecell: [
                      {
                        "Available Time (Min.)": `${formatNumber(
                          productionMessage?.shift?.availabletime
                        )} min`,
                      },
                      {
                        "Availability Rate": `${formatNumber(
                          productionMessage?.availabilityrate
                        )}%`,
                      },
                      {
                        "Bottleneck cycle time (Sec)": `${formatNumber(
                          productionMessage?.bottleneckcycletime
                        )} sec`,
                      },
                      {
                        "Performance Rate": `${formatNumber(
                          productionMessage?.performancerate
                        )}%`,
                      },
                      {
                        "Rejection (Nos.)": formatNumber(
                          productionMessage?.actualngpart
                        ),
                      },
                      {
                        "Quality Rate": `${formatNumber(
                          productionMessage?.qualityrate
                        )}%`,
                      },
                      { OEE: `${formatNumber(productionMessage?.oee)}%` },
                      {
                        "OEE (Loss)": `${formatNumber(
                          productionMessage?.oeeloss
                        )}%`,
                      },
                    ],
                    piechart: {
                      labels: ["OEE", "OEE Loss"],
                      values: [
                        formatNumber(productionMessage?.oee),
                        formatNumber(productionMessage?.oeeloss),
                      ],
                    },
                  }
                : item
            );
          });
          setOEEData([productionMessage]);
        }
      }
    }
  }, [productionMessage, getReportServiceData]);

  useEffect(() => {
    if (
      bomcreationmaster?.length > 0 &&
      !model &&
      Object.keys(lineproduction) == 0
    ) {
      setModel(bomcreationmaster?.find((i) => i.recstatus == "true")?.modelno);
      fetchData(
        bomcreationmaster?.find((i) => i.recstatus == "true")?.modelno,
        date
      );
    }
  }, [bomcreationmaster, date, lineproduction]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime((prevTime) => {
        const newTime = new Date();
        if (newTime.getMinutes() !== prevTime.getMinutes()) {
          return newTime;
        }
        return prevTime;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newDate = getCurrentDateStringReadable();
      if (newDate !== currentDateString) {
        setCurrentDateString(newDate);
        const newISODate = new Date().toISOString().slice(0, 10);
        setDate(newISODate);
        fetchData(model, newISODate);
      }
    }, 60000);

    return () => clearInterval(intervalId);
  }, [currentDateString, model]);

  const fetchData = (model, date) => {
    const formatteddate = new Date(date).toLocaleDateString("en-GB");
    try {
      dispatch(
        reportServiceAction({ model: model, formatteddate: formatteddate })
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      setData([defaultData]);
      setOEEData([]);
    }
  };

  useEffect(() => {
    if(!bomcreationmaster.length){
      dispatch(resetReportServiceList());
      return;
    }

    const oeeprod = JSON.parse(localStorage.getItem("lineproduction"));
    if (oeeprod && auth) {
      const formatteddate = new Date(oeeprod.date).toLocaleDateString("en-GB");
      try {
        dispatch(
          reportServiceAction({
            model: oeeprod?.model,
            formatteddate: formatteddate,
          })
        );
      } catch (error) {
        console.error("Error fetching data:", error);
        setData([defaultData]);
        setOEEData([]);
      }
    }
  }, [bomcreationmaster]);

  useEffect(() => {
    if(auth == null || !auth){
      setDate(getCurrentDateString());
      setModel(bomcreationmaster?.find((i) => i.recstatus == "true")?.modelno);
      fetchData(
        bomcreationmaster?.find((i) => i.recstatus == "true")?.modelno,
        date
      );
    }
  },[auth,bomcreationmaster])

  useEffect(() => {
    if (getReportServiceData?.list?.length > 0) {
      setOEEData(getReportServiceData?.list);
      setData(
        getReportServiceData?.list.map((item) => ({
          linecode: item?.linecode,
          line: item?.linename,
          shiftno: item?.shift?.title.replace("-", " "),
          tablecell: [
            {
              "Available Time (Min.)": `${formatNumber(
                item?.shift?.availabletime
              )} min`,
            },
            { "Availability Rate": `${formatNumber(item?.availabilityrate)}%` },
            {
              "Bottleneck cycle time (Sec)": `${formatNumber(
                item?.bottleneckcycletime
              )} sec`,
            },
            { "Performance Rate": `${formatNumber(item?.performancerate)}%` },
            { "Rejection (Nos.)": formatNumber(item?.actualngpart) },
            { "Quality Rate": `${formatNumber(item?.qualityrate)}%` },
            { OEE: `${formatNumber(item?.oee)}%` },
            { "OEE (Loss)": `${formatNumber(item?.oeeloss)}%` },
          ],
          piechart: {
            labels: ["OEE", "OEE Loss"],
            values: [formatNumber(item?.oee), formatNumber(item?.oeeloss)],
          },
        }))
      );
    } else {
      setData([defaultData]);
    }

    if (getReportServiceData?.error) {
      swal(
        "Oops!",
        getReportServiceData?.error?.response?.data || "Something went wrong.",
        "error"
      );
    }
  }, [getReportServiceData]);

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    setDate(selectedDate);
    let lineproduction = JSON.parse(localStorage.getItem("lineproduction")) || {};
    lineproduction = {
      ...lineproduction,
      date: selectedDate,
    };
    if (model) {
      lineproduction.model = model;
      fetchData(model, selectedDate);
    }
    localStorage.setItem("lineproduction", JSON.stringify(lineproduction));
  };
  
  const handleModelChange = (event) => {
    const selectedModel = event.target.value;
    setModel(selectedModel);
    let lineproduction = JSON.parse(localStorage.getItem("lineproduction")) || {};
    lineproduction = {
      ...lineproduction,
      model: selectedModel,
    };
    if (date) {
      lineproduction.date = date;
      fetchData(selectedModel, date);
    }
    localStorage.setItem("lineproduction", JSON.stringify(lineproduction));
  };

  const oeedate = {
    background: "#ffffff",
    height: "42px",
    width: "100%",
    borderRadius: "8px",
    padding: "0px 10px",
  };

  const OEECard = () => (
    <Card sx={{ backgroundColor: "#015883", color: "#ffffff", borderRadius:`${!auth && "0px"}`}}>
    <Grid
      container
      spacing={2}
      xs={11}
      mt={5}
      ml="auto"
      mr="auto"
      className="oeeparent"
    >
      <Grid item xs={12} md={3} className="oeegrid">
        Today - {currentDateString}
      </Grid>
      <Grid item xs={12} md={3} className="oeegrid oeegridmodel">
        Model
      </Grid>
      <Grid item xs={12} md={3} className="oeegrid">
        Current Time - {currentTimeString}
      </Grid>
      <Grid container item xs={12} md={3} className="oeegrid1">
        <Grid item xs={8.5} lg={9} className="oeetable">
          Planned Downtime (Min.)
        </Grid>
        <Grid item xs={3.5} lg={3} className="oeetable2">
          {oeeData.length > 0 ? oeeData[0]?.shift?.planneddowntime : "NA"}
        </Grid>
      </Grid>
    </Grid>
    <Grid
      container
      spacing={2}
      xs={11}
      mt={1}
      ml="auto"
      mr="auto"
      className="oeeparent"
    >
      <Grid item xs={12} md={3} className="oeegrid">
        <MDDatePickerMod
          input={{ placeholder: "Select a date" }}
          value={date}
          onChange={handleDateChange}
          disabled={!auth && true}
        />
        {/* <Input
          sx={oeedate}
          placeholder="Select Date"
          type="date"
          value={date}
          onChange={handleDateChange}
        /> */}
      </Grid>
      <Grid item xs={12} md={3} className="oeegrid">
        <select style={oeedate} value={model} onChange={handleModelChange}>
          {bomcreationmaster?.length == 0 && (
            <option className="lineproductionoptions" value="" disabled selected>
              Select Model
            </option>
          )}
          {bomcreationmaster.map((item, index) => (
            <option className="oeeoptions" value={item.modelno} key={index}>
              {item.modelno}
            </option>
          ))}
        </select>
      </Grid>
      <Grid item xs={12} md={3} className="oeegrid">
        <Input
          sx={oeedate}
          value={
            oeeData.length > 0
              ? `Current Shift - ${oeeData[0]?.shift?.title.replace("-", " ")}(${
                  oeeData[0]?.shift?.starttime
                } - ${oeeData[0]?.shift?.endtime})`
              : "Set Shift"
          }
          readOnly
        />
      </Grid>
      <Grid container item xs={12} md={3} className="oeegrid1">
        <Grid item xs={8.5} lg={9} className="oeetable">
          Available Time (Min.)
        </Grid>
        <Grid item xs={3.5} lg={3} className="oeetable2">
          {oeeData.length > 0 ? oeeData[0]?.shift?.availabletime : "NA"}
        </Grid>
      </Grid>
    </Grid>
    <Grid container className="oeegrid2" mt={3} pl={3} mb={2} pr={3}>
      {data.map((item, index) => (
        <Grid item xs={12} md={5.6} key={index}>
          {getReportServiceData?.loading ? (
            <Card>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "60vh",
                }}
              >
                <CircularProgress />
              </div>
            </Card>
          ) : (
            <LineProductionTable data={item} />
          )}
        </Grid>
      ))}
    </Grid>
  </Card>
  )

  return (
    <>
      {auth ? (
        <DashboardLayout>
          <DashboardNavbar />
          <OEECard />
        </DashboardLayout>
      ) : (
        <OEECard />
      )}
    </>
  );
};

export default OEE;
